import scrollToElement from 'scroll-to-element';

export const onRouteChange = (location: Location) => {
  let { hash } = location
  if (hash) {
    scrollToElement(hash, {
      offset: -95,
      duration: 1000,
    })
  }
}

export function onInitialClientRender() {

  const requestAnimationFrame = polyfillAnimationFrame();

  const addEventListenerHandler = () => requestAnimationFrame(visibilityHandler);

  addEventListenerHandler();

  window.addEventListener('load', addEventListenerHandler, false);

  window.addEventListener('scroll', addEventListenerHandler, false);

  window.addEventListener('resize', addEventListenerHandler, false);

  window.addEventListener('mousemove', trackCursorPosition);

  document.documentElement.classList.add('is-loaded');

  document.documentElement.classList.add(`menu-closed`);

  setTimeout(() => document.documentElement.classList.add('is-ready'), 300);

}

function polyfillAnimationFrame() {
  let requestAnimationFrame = (!window) ? null : window.requestAnimationFrame;
  if (!requestAnimationFrame) {
    requestAnimationFrame = function (callback) {
      return window.setTimeout(function () {
        callback(Date.now());
      }, 1000 / 60);
    };
  }
  return requestAnimationFrame
}

const elementInViewport = (el: Element) => {
  const scroll = window.scrollY || window.pageYOffset;
  const boundsTop = el.getBoundingClientRect().top + scroll
  const viewport = { top: scroll, bottom: scroll + window.innerHeight }
  const bounds = { top: boundsTop, bottom: boundsTop + el.clientHeight }
  return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom)
    || (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
}

function onVisibilityChange(element: Element, previousValue: boolean) {
  var currentValue = elementInViewport(element);
  if (currentValue === previousValue) return; //Nothing to update
  if (!currentValue) element.classList.remove('is-inview');
  if (!!currentValue) element.classList.add('is-inview');
}

const visibilityHandler = () => {
  document.querySelectorAll('[data-track-view]').forEach((el) => {
    var previousValue = el.classList.contains('is-inview');
    onVisibilityChange(el, previousValue);
  });
};

function trackCursorPosition(event) {
  let cursor = document.getElementById('cursor');
  const { clientX, clientY, pageX, pageY } = event;
  if (!!cursor) {
    cursor.style.left = `${(pageX || clientX) - 5}px`;
    cursor.style.top = `${(pageY || clientY) - 5}px`;
  }
}
