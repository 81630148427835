const actions = require('./src/utils/browser');
const scrollToElement = require('scroll-to-element');

exports.onRouteUpdate = ({ location }) => {
  actions.onRouteChange(location);
}

exports.onInitialClientRender = () => {
  actions.onInitialClientRender();
};
